import { Table, Modal, Button, Pagination } from "antd"
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { LANGUAGES, COUNTRIES } from "../../shared/constants"
import { format } from 'date-fns'
import { Cross } from "../../shared/icons"
import { PDFViewer } from 'react-view-pdf';
import styles from "./grid.module.css"

const loadContent = async (domain, request) => {
    try {
        const url = `${process.env.REACT_APP_OUTBACK_API_URL}/${process.env.REACT_APP_STAGE}/renderWithUrl`
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "OutbackDomain": domain
            },
            body: JSON.stringify(request)
        })
        const data = await response.json()
        return data   
    } catch (error) {
        return null
    }
}

export const Grid = ({ data, onChange, setLoading }) => {
    const [modalVisible, setModalVisible] = useState(false)
    const [file, setFile] = useState(null)
    const { domain } = useParams()
    const view = async (row) => {
        setLoading(true)

        const request = row.fileKey
            ? { fileKey: row.fileKey }
            : { datasetKey: row.datasetKey }
        const url = await loadContent(domain, request)
        if (url) {
            setFile(url)
            setModalVisible(true)
        }

        setLoading(false)
    }

    const columns = [
        {
            title: 'Material',
            key: 'material',
            render: (row) => {
                const language = LANGUAGES.find(l => l.code === row.languageCode?.split(",")[0])
                const country = COUNTRIES.find(l => l.code === row.countryCode?.split(",")[0])
                const date = format(row.issueDate ? new Date(row.issueDate) : new Date(), 'dd/MM/yyyy')
                return (
                    <div className={styles.name_block}>
                        {
                            (row.fileKey || row.datasetKey) ?
                                <button
                                    className={styles.material_name}
                                    onClick={() => view(row)}
                                >
                                    {row.materialName}
                                </button>
                                :
                                <p
                                    className={styles.material_name}
                                >
                                    {row.materialName}
                                </p>
                        }

                        <p className={styles.vendor_name}>{row.vendorName}</p>
                        <p className={styles.aditional_info}>
                            {country?.name}
                            <span className={styles.divider} />
                            {language?.name}
                            <span className={styles.divider} />
                            {date}
                        </p>
                    </div>
                )
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 100,
            align: "right",
            render: (row) => ((row.fileKey || row.datasetKey) &&
                <Button
                    onClick={() => view(row)}
                >
                    View
                </Button>
            )
        }
    ];

    const handlePagination = async (page, pageSize) => {
        const from = (page - 1) * pageSize;
        const size = pageSize;

        onChange({ from, size })
    }

    const [expanded, setExpanded] = useState(false)
    const expand = () => {
        setExpanded(!expanded)
    }

    return (
        <>
            <div className="outback-main-grid-inner">
                <Table
                    size="small"
                    dataSource={data && data.data}
                    columns={columns}
                    rowKey="socketId"
                    pagination={false}
                />
            </div>

            {data.totalResults ?
                <div className="grid-custom-pagination">
                    <Pagination
                        size="small"
                        current={data && data.currentPage}
                        total={data && data.totalResults}
                        position="bottomRight"
                        showSizeChanger={true}
                        pageSizeOptions={["25", "50", "75", "100", "500"]}
                        defaultPageSize={25}
                        showTotal={(total, range) => `${range.join("–")} / ${total}`}
                        onChange={handlePagination}
                    />
                </div>
                : null
            }

            {modalVisible && (
                <Modal
                    centered
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    width={1000}
                    closeIcon={<Cross />}
                    wrapClassName={expanded ? "expanded-modal-window" : ""}
                    title={[
                        <div className="custom-header-for-modal-window-with-expand-button" key="custom-header-for-modal-window-with-expand-button">
                            Document Preview
                            <button
                                type="button"
                                className={expanded ? "custom-expand-collapse-button-for-modal-window collapse" : "custom-expand-collapse-button-for-modal-window"}
                                onClick={expand}
                                title={expanded ? "Collapse Window" : "Expand-Window"}
                            />
                        </div>
                    ]}
                    footer={[
                        <Button key="back" onClick={() => setModalVisible(false)}>
                            Cancel
                        </Button>
                    ]}
                >
                    <div className={styles.pdf_wrapper}>
                        <PDFViewer url={file} />
                    </div>
                </Modal>
            )}
        </>
    )
}